<script>
    import localizer from "~/common/i18n.js";
    import ClickOutsideParent from "@/components/ClickOutside.svelte";
    import Dialog from "@/components/Dialog.svelte";


    /**
     * @typedef {Object} Props
     * @property {any} profileFrontPath
     * @property {any} indexPath
     * @property {any} whatIsAidPath
     * @property {any} privacyPath
     * @property {any} faqPath
     * @property {any} termsOfServicePath
     * @property {any} websitesPath
     * @property {any} aboutAidFamilyPath
     * @property {any} infoForCompaniesPath
     * @property {any} logoutPath
     * @property {boolean} [userLoggedIn]
     */

    /** @type {Props} */
    let {
        profileFrontPath,
        indexPath,
        whatIsAidPath,
        privacyPath,
        faqPath,
        termsOfServicePath,
        websitesPath,
        aboutAidFamilyPath,
        infoForCompaniesPath,
        logoutPath,
        userLoggedIn = false
    } = $props();

    let menu = $state();
    let isOpen = $state(false);
    let closing = $state(false);

    function openMenu() {
        if (isOpen || closing) {
            return; // Stupid iOS!
        }
        isOpen = true;
        menu.showModal();
    }

    function closeMenu() {
        closing = true;
        isOpen = false;
        setTimeout(() => {
            menu.close();
            closing = false;
        }, 400);
    }

    function toggleOpenClosed() {
        if (isOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    }

     const t = localizer({
         'nb-NO': {
             header: "Meny",
             index: "Forsiden",
             whatIsAid: "Hva er aID?",
             privacy: "Personvern",
             faq: "Spørsmål og svar",
             termsOfService: "Brukervilkår",
             websites: "Nettsteder som bruker aID for innlogging",
             myAid: "Min aID",
             aboutAidFamily: "Informasjon om aID Familie",
             infoForCompanies: "For bedrifter",
             logout: "Logg ut",
             close: 'Lukk',
             menu: 'Meny',

         },
         'nn-NO':{
             header: "Meny",
             index: "Framsida",
             whatIsAid: "Kva er aID?",
             privacy: "Personvern",
             faq: "Spørsmål og svar",
             termsOfService: "Brukarvilkår",
             websites: "Nettstader som brukar aID for innlogging",
             myAid: "Min aID",
             aboutAidFamily: "Informasjon om aID Familie",
             infoForCompanies: "For bedriftar",
             logout: "Logg ut",
             close: 'Lukk',
             menu: 'Meny',
         },
         'da-DK': {
             header: "Menu",
             index: "Forsiden",
             whatIsAid: "Hvad er aID?",
             privacy: "Privatliv",
             faq: "Spørgsmål og svar",
             termsOfService: "Brugerbetingelser",
             websites: "Websteder, der bruger aID til indlogning",
             myAid: "Mit aID",
             aboutAidFamily: "Information om aID Familie",
             infoForCompanies: "For virksomheder",
             logout: "Log ud",
             close: 'Luk',
             menu: 'Menu',
         }
     });
</script>

<div class="{isOpen ? 'open' : 'closed'}">
    <Dialog bind:dialog={menu} onclose={() => {isOpen = false;}}>
        <ClickOutsideParent enabled={isOpen || closing} onOutsideClicked={closeMenu}>
            <nav>
                <ul>
                    <li><a href="{profileFrontPath}">{t('myAid')}</a></li>
                    <li><a href="{indexPath}">{t('index')}</a></li>
                    <li><a href="{whatIsAidPath}">{t('whatIsAid')}</a></li>
                    <li><a href="{privacyPath}">{t('privacy')}</a></li>
                    <li><a href="{faqPath}">{t('faq')}</a></li>
                    <li><a href="{termsOfServicePath}">{t('termsOfService')}</a></li>
                    <li><a href="{websitesPath}">{t('websites')}</a></li>
                    <li><a href="{aboutAidFamilyPath}">{t('aboutAidFamily')}</a></li>
                    <li><a href="{infoForCompaniesPath}">{t('infoForCompanies')}</a></li>
                    {#if userLoggedIn}
                        <li class="lp_logout_aidno" data-action="logout"><a href="{logoutPath}">{t('logout')}</a></li>
                    {/if}
                </ul>
            </nav>
        </ClickOutsideParent>
    </Dialog>
    <button onclick={toggleOpenClosed} class="headermenu-button">
        <svg viewBox="0 0 60 36" xmlns="http://www.w3.org/2000/svg">
            <path d="M 0,0 H 42 c 20,0 20,30 -3,30 L 6,0" />
            <path d="M 0,15 H 42" />
            <path d="M 0,30 H 42 c 20,0 20,-30 -3,-30 L 6,30" />
        </svg>
        <span class="aid-headermenu--label" data-close-label={t('close')}>{t('menu')}</span>
    </button>
</div>

<style>
    @import '../common/colors.css';

    :root {
        --easing: cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }

    div  {
        text-align: center;
        width: 60px;
        margin-left: auto;
    }

    div.open {
        background: transparent;
    }

    div > button {
        display: block;
        background: none;
        border: none;
        color: var(--white);
        font-weight: bold;
        font-size: 17px;
        position: relative;
        z-index: 5;
    }

    div svg {
        display: block;
        margin: 0 auto;
        position: relative;
        left: 5px;
        width: 36px;
        height: 30px;
        margin-bottom: 5px;
    }

    span {
        display: block;
        color: var(--white);
        font-size: 17px;
        font-weight: bold;
        position: relative;
        transition: color 250ms ease;
    }

    div span::before {
        position: absolute;
        color: transparent;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: attr(data-close-label);
        transition: color 250ms ease;
        text-align: center;
    }

    div.open span {
        color: transparent;
    }

    div.open span::before {
        color: var(--white);
    }

    div path {
        fill: none;
        stroke: var(--white);
        stroke-width: 6px;
        stroke-dasharray: 42px 128px;
        stroke-dashoffset: 0;
    }

    div path {
        transition: transform 250ms var(--easing) 0ms,
        stroke-dashoffset 250ms var(--easing) 150ms;
    }

    div:focus svg,
    div:hover svg {
        transform: scale(1.1);
    }

    div.open path:nth-child(2) {
        transform: translateX(200%);
    }

    div.open path:nth-child(1),
    div.open path:nth-child(3) {
        stroke-dashoffset: -93px;
    }

    div > :global(dialog::backdrop),
    div > :global(dialog + .backdrop) /* added due to limitation of polyfill (@see https://github.com/GoogleChrome/dialog-polyfill?tab=readme-ov-file#backdrop) */ {
        background: rgba(30, 40, 45, 0.8);
        box-sizing: border-box;
        opacity: 0;
        position: absolute;
        transition: opacity 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950) 0ms,
        height 150ms cubic-bezier(0.445, 0.050, 0.550, 0.950) 0ms,
        width 150ms cubic-bezier(0.445, 0.050, 0.550, 0.950) 0ms;
        z-index: 4;
        overflow: hidden;
        width: 0;
        height: 0;
        right: 0;
        left: auto;
        top: 189px;
    }

    div.open > :global(dialog::backdrop),
    div.open > :global(dialog + .backdrop) /* added due to limitation of polyfill (@see https://github.com/GoogleChrome/dialog-polyfill?tab=readme-ov-file#backdrop) */ {
        opacity: 1;
        width: 100%;
        height: 3000px;
    }

    div > :global(dialog) {
        position: absolute;
        transition: right 150ms var(--easing) 0ms,
        height 150ms var(--easing) 0ms;
        border: 0;
        padding: 0;
        background-color: var(--black);
        max-width: 100%;
        margin:0;
        top: 223px;
        height: 0;
        right: -280px;
        left: auto;
        width: 280px;
    }

    div.open > :global(dialog) {
        height: 100%;
        right: 0;
    }

    div > :global(dialog nav ul) {
        right: 0;
        bottom: 0;
        margin: 0;
    }

    nav li {
        border-bottom: 3px solid var(--menuBorder);
        list-style: none;
        margin-left: 0;
        text-align: left;
        cursor: pointer;
    }

    nav li a {
        color: var(--white);
        text-decoration: none;
        padding: 15px 20px;
        display: block;
        font-size: 17px;
        font-weight: bold;
    }

    @media screen and (max-width: 1000px) {
        div  {
            width: 50px;
        }

        div > :global(dialog[open]::backdrop),
        div > :global(dialog[open] + .backdrop) /* added due to limitation of polyfill (@see https://github.com/GoogleChrome/dialog-polyfill?tab=readme-ov-file#backdrop) */ {
            top: 92px;
        }

        div > :global(dialog[open]) {
            top: 95px;
        }

        nav li {
            list-style: none;
        }

        span {
            font-size: 13px;
        }
    }

    @media screen and (max-width: 550px) {
        div > :global(dialog[open]::backdrop),
        div > :global(dialog[open] + .backdrop) /* added due to limitation of polyfill (@see https://github.com/GoogleChrome/dialog-polyfill?tab=readme-ov-file#backdrop) */ {
            top: 80px;
        }

        div > :global(dialog[open]) {
            top: 83px;
        }

        div  {
            padding-left: 0;
            width: auto;
        }
    }

    @media screen and (max-width: 500px) {
        nav ul {
            right: -100%;
        }

        div.open nav ul {
            width: 100%;
        }
    }

    @media screen and (max-width: 400px) {
        nav li a {
            font-size: 16px;
        }
    }

    @media (prefers-reduced-motion: reduce) {
        div > :global(dialog),
        div > :global(dialog::backdrop),
        div > :global(dialog + .backdrop), /* added due to limitation of polyfill (@see https://github.com/GoogleChrome/dialog-polyfill?tab=readme-ov-file#backdrop) */
        div path,
        div svg {
            transition: none;
        }
    }
</style>