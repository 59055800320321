<script>
    import AidLogo from "~/components/AidLogo.svelte";
    import HeaderMenu from "~/components/HeaderMenu.svelte";
    import { onMount } from "svelte";
    import localizer from "@/common/i18n.js";

    /**
     * @typedef {Object} Props
     * @property {boolean} [hideMenu]
     * @property {any} heading
     * @property {any} userLoggedIn
     * @property {any} profileFrontPath
     * @property {any} indexPath
     * @property {any} whatIsAidPath
     * @property {any} privacyPath
     * @property {any} faqPath
     * @property {any} termsOfServicePath
     * @property {any} websitesPath
     * @property {any} aboutAidFamilyPath
     * @property {any} infoForCompaniesPath
     * @property {any} logoutPath
     */

    /** @type {Props} */
    let {
        hideMenu = false,
        heading,
        userLoggedIn,
        profileFrontPath,
        indexPath,
        whatIsAidPath,
        privacyPath,
        faqPath,
        termsOfServicePath,
        websitesPath,
        aboutAidFamilyPath,
        infoForCompaniesPath,
        logoutPath
    } = $props();

    const t = localizer({
        'nb-NO': {
            toFrontPage: 'Gå til aID-forsiden',
        },
        'da-DK': {
            toFrontPage: 'Gå til aID-forsiden',
        }
    })

    onMount(() => {
       if (userLoggedIn && 'IdentityProvider' in window) {
           window.IdentityProvider.close();
       }
    });
</script>

<header class="aid-header">
    <div class="aid-header-content">
        <a class="aid-header--logo" aria-label={t('toFrontPage')} href="/aid/">
            <AidLogo variant="circle" />
        </a>
        {#if heading && heading !== ''}
            <h1>{@html heading}</h1>
        {/if}

        {#if !hideMenu}
            <HeaderMenu {userLoggedIn}
                        {profileFrontPath} {indexPath} {whatIsAidPath} {privacyPath} {faqPath} {termsOfServicePath}
                        {websitesPath} {aboutAidFamilyPath} {infoForCompaniesPath} {logoutPath}
            />
        {/if}
    </div>
</header>

<style>
    @import "../common/colors.css";

    .aid-header {
        background: var(--black);
        width: 100%;
        flex-grow: 0;
    }

    .aid-header-content {
        display: flex;
        align-items: center;
        max-width: 1000px;
        margin: 0 auto;
        padding: 100px 20px 30px 20px;
    }

    .aid-header--logo {
        width: 90px;
        margin-right: 22px;
    }

    .aid-header h1 {
        box-sizing: border-box;
        color: var(--white);
        line-height: 1.2;
        font-weight: bold;
        font-size: 24px;
        margin: 0 40px 0 0;
    }

    @media screen and (max-width: 1000px) {
        .aid-header-content {
            padding: 20px 13px;
        }

        .aid-header--logo {
            width: 43px;
        }

        .aid-header h1 {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 550px) {
        .aid-header-content {
            padding-top: 0;
            padding-bottom: 0;
            flex-wrap: wrap;
            align-items: center;
        }

        .aid-header--logo {
            min-height: 80px;
            display: flex;
            align-items: center;
        }

        .aid-header h1 {
            box-sizing: content-box;
            background: var(--lightGray);
            color: var(--black);
            width: 100%;
            padding: 13px;
            font-size: 17px;
            order: 2;
            margin-left: -13px;
            margin-right: -13px;
        }
    }

    @media screen and (max-width: 400px) {
        .aid-header h1 :global(br) {
            display: none;
        }
    }
</style>